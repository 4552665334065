import get from "lodash.get";
import set from "lodash.set";

import { toSnakeCase } from "~/components/output-types/jsonapp.helpers";

/**
 * @func getFetchReport
 * @desc get the 'fetchReport' key from cache. create it as an empty object if it doesn't exist
 * @param {Object} cache - the cache object
 * @param {String} key - the cache key
 * @return {Object} the object stored at the cache key
 */
const getFetchReport = (cache, key = "fetchReport") => {
  const cacheData = cache.extract();
  const initData = {};
  const existingData = get(cacheData, key, initData);
  if (existingData === initData) set(cacheData, key, initData);
  return existingData;
};

/**
 * @func updateFetchReport
 * @desc generates an object with config as key and data needed for health report
 * @param {Object} cache - the cache object
 * @param {Object} parent - the parent (chain) where the content comes from
 * @param {String} id - the id of the content
 * @param {String} source - the pcs content source
 * @param {Object} query - the pcs content config
 * @param {Object} content - the fetched content
 * @param {String} key - the key used by jsonapp for the content source/config when doing the batch fetch
 * @return {Object}
 * {
 *   contentConfigAsString: {
 *     ok: bool, // whether the fetch succeeded or not
 *     chainId: { // chains containing the content
 *       featureId': { key: enum:content|videoData|elex-live-graphic|+more } // where the content comes from
 *       ...
 *     }
 *     ...
 *   }
 * }
 */
const updateFetchReport = (cache, parent, id, source, query, content, key) => {
  if (!/empty/.test(source)) {
    const parentId = parent?.props?.id;
    const config = { source, query };
    const fetchReport = getFetchReport(cache);

    const { ok, ...rest } = fetchReport[JSON.stringify(config)] || {};
    const parentObj = rest[parentId] || {};
    const obj = parentObj[id] || { key };
    parentObj[id] = obj;

    fetchReport[JSON.stringify(config)] = {
      ok: !!content,
      ...rest,
      [`${parentId}`]: parentObj
    };
  }
};

/**
 * @func updateFetchReportAll
 * @desc generates an object for each config in the document needed to generate health report
 * @param {Object} cache - the cache object
 * @param {Object} parent - the parent (chain) where the content comes from
 * @param {String} id - the id of the content
 * @param {Object} state - this.state from the jsonapp code which has the batch request/response info
 * @param {Object} contentConfig - the assembler content config (combo of content source and query params)
 * @return {Object}
 * {
 *   contentConfigAsString: report (see updateFetchReport for structure of report)
 *   ...
 * }
 */
export const updateFetchReportAll = (
  cache,
  parent,
  id,
  state,
  contentConfig
) => {
  Object.keys(contentConfig).forEach((key) => {
    updateFetchReport(
      cache,
      parent,
      id,
      contentConfig[key].source || contentConfig[key].contentService,
      contentConfig[key].query || contentConfig[key].contentConfigValues,
      state[key],
      key
    );
  });
};

const generateSummaryReducer = (acc, { total, success }) => {
  acc.total += /^\d+$/.test(total) ? total : 0;
  acc.success += /^\d+$/.test(success) ? success : 0;
  return acc;
};

const summarize = (report) => {
  const summary = report.reduce(generateSummaryReducer, {
    success: 0,
    total: 0
  });
  summary.rate =
    summary.total > 0 ? (100 * summary.success) / summary.total : null;
  return summary;
};

const cleanUpChains = (report) => {
  if (!report) return report;
  return Object.keys(report).reduce((acc, key) => {
    const { displayNames, ...props } = report[key];
    acc[key] =
      displayNames?.length === 1 && displayNames[0] === key
        ? props
        : report[key];
    return acc;
  }, {});
};

const cleanUp = (report) => {
  return {
    ...toSnakeCase({
      content: report.content,
      chains: cleanUpChains(report.chains)
    }),
    not_ok: report.notOk
  };
};

/**
 * @func generateHealthReport
 * @desc generates a health report given the fetchReport and the regions object of the jsonapp report
 * @param {Object} cache - the cache object containg the fetchReport
 * @param {Array} regions - the regions array from the jsonapp doc
 * @param {Object} tree - the tree object representing the curated page
 * @return {Object}
 * {
 *   content: {
 *     overall: summary,
 *     top_3: summary,
 *     [essentialChains]: summary
 *   },
 *   chains: {
 *     overall: summary,
 *     top_3: summary,
 *     [essentialChains]: summary
 *   }
 * }
 *
 * ...where summary is of the form:
 * {
 *   succes: int,
 *   total: int,
 *   rate: success/total
 * }
 */
export const generateHealthReport = (cache, regions, tree) => {
  const fetchReport = cache?.extract()?.fetchReport || {};

  const allChainsById = tree.children[2].children
    .filter(
      ({ collection, type }) => collection === "chains" && type === "top-table"
    )
    .reduce(
      (
        acc,
        {
          props: {
            id,
            customFields: { displayName }
          }
        }
      ) => {
        acc[id] = { displayName };
        return acc;
      },
      {}
    );

  // NOTE: The list of all chains curated onto the page. It's possible that any number of them
  // are not intended to render, e.g. web-only or a chain with a breaking news feed when there
  // isn't any breaking news
  const allChainIds = Object.keys(allChainsById);

  // NOTE: Not all chains are guaranteed to have monitored content, e.g. a chain full
  // of newsletters or the diversions chain. If a chain has monitored content, it will
  // appear somewhere in the fetchReport
  const monitoredChainIds = Object.keys(
    Object.keys(fetchReport).reduce((acc, fingerprint) => {
      const { ok, ...report } = fetchReport[fingerprint];
      Object.keys(report).forEach((id) => {
        acc[id] = true;
      });
      return acc;
    }, {})
  );

  // NOTE: The ids of chains actually due to render
  const renderedChainIds = (
    regions.find(({ location }) => location === "content")?.items || []
  )
    .filter(({ itemType }) => itemType === "grid")
    .map(({ id }) => id);

  // NOTE: The ids of rendered chains that have only unomitored content
  const unmonitoredChainIds = renderedChainIds.filter(
    (id) => !monitoredChainIds.includes(id)
  );

  const curatedChainIds = [...monitoredChainIds, ...unmonitoredChainIds];

  // NOTE: The ids of chains absent from the render
  const absentChainIds = curatedChainIds.filter(
    (id) => !renderedChainIds.includes(id)
  );

  const getHealthReport = (chainIds) =>
    chainIds.map((chainId) => {
      let total = 0;
      let success = 0;
      const uses = {};
      Object.keys(fetchReport).forEach((key) => {
        const { ok, ...report } = fetchReport[key];
        if (report[chainId]) {
          let count = 0;
          Object.keys(report[chainId]).forEach((id) => {
            count += 1;
            const use = report[chainId][id].key;
            if (!uses[use]) {
              uses[use] = { total: 0, success: 0 };
            }
            uses[use].success += ok ? 1 : 0;
            uses[use].total += 1;
          });
          success += ok ? count : 0;
          total += count;
        }
      });

      return {
        chainId,
        rate: total > 0 ? (100 * success) / total : null,
        total,
        success,
        uses
      };
    });

  const healthReportFromAbsentChains = getHealthReport(absentChainIds);

  // NOTE: The ids of the subset of absent chain ids with monitored content with a success rate of 0
  const missingChainIds = absentChainIds.reduce((acc, id, i) => {
    const report = summarize(healthReportFromAbsentChains.slice(i, i + 1));
    if (report.total > 0 && report.rate === 0) acc.push(id);
    return acc;
  }, []);

  // NOTE: The combined ids of the rendered and missing chain ids in their curated order
  // NOTE: This is the holy grail!
  const intendedChainIds = [...renderedChainIds, ...missingChainIds]
    .reduce((acc, id) => {
      acc[`${allChainIds.indexOf(id)}`] = id;
      return acc;
    }, [])
    .filter((_) => typeof _);

  const healthReportFromIntendedChains = getHealthReport(intendedChainIds);
  const contentSummaryOfIntendedChains = summarize(
    healthReportFromIntendedChains
  );

  const chainSummary = (() => {
    const success = renderedChainIds.length;
    const total = intendedChainIds.length;
    return {
      success,
      total,
      rate: total > 0 ? (100 * success) / total : null
    };
  })();

  const makeChainReport = (id) => {
    const success = renderedChainIds.indexOf(id) > -1 ? 1 : 0;
    const total = intendedChainIds.indexOf(id) > -1 ? 1 : 0;
    return {
      success,
      total,
      rate: total > 0 ? (100 * success) / total : null
    };
  };

  const summarizeTopXChains = (x) => {
    const topIntendedChainIds = intendedChainIds.slice(0, x);
    const topRenderedChainIds = renderedChainIds.slice(0, x);
    const total = topIntendedChainIds.length;
    const { success, displayNames } = topIntendedChainIds.reduce(
      (acc, id, i) => {
        acc.success +=
          topRenderedChainIds.indexOf(topIntendedChainIds[i]) !== -1 ? 1 : 0;
        acc.displayNames.push(allChainsById?.[id]?.displayName);
        return acc;
      },
      { success: 0, displayNames: [] }
    );
    return {
      displayNames,
      success,
      total,
      rate: total > 0 ? (100 * success) / total : null
    };
  };

  const getIdByDisplayName = (name) =>
    Object.keys(allChainsById).find((id) => {
      return name === allChainsById?.[id]?.displayName;
    });

  const essentialChainNames = Object.keys(allChainsById).reduce((acc, id) => {
    const { displayName } = allChainsById[id];
    if (/top.?table/i.test(displayName)) acc.push(displayName);
    return acc;
  }, []);

  const { essentialContentSummaries, essentialChainSummaries } =
    essentialChainNames.reduce(
      (acc, name) => {
        const id = getIdByDisplayName(name);
        const idx = intendedChainIds.indexOf(id);
        if (idx > -1) {
          acc.essentialContentSummaries[name] = summarize(
            healthReportFromIntendedChains.slice(idx, idx + 1)
          );
          acc.essentialChainSummaries[name] = makeChainReport(id);
        }
        return acc;
      },
      { essentialContentSummaries: {}, essentialChainSummaries: {} }
    );

  const topX = 3;

  const report = {
    ...(contentSummaryOfIntendedChains.rate !== null
      ? {
          content: {
            overall: contentSummaryOfIntendedChains,
            [`top${topX}`]: summarize(
              healthReportFromIntendedChains.slice(0, topX)
            ),
            ...essentialContentSummaries
          }
        }
      : {}),
    ...(chainSummary.rate !== null
      ? {
          chains: {
            overall: chainSummary,
            [`top${topX}`]: summarizeTopXChains(topX),
            ...essentialChainSummaries
          }
        }
      : {}),
    ...(contentSummaryOfIntendedChains.rate !== null &&
    contentSummaryOfIntendedChains.rate < 100
      ? {
          notOk: Object.keys(fetchReport)
            .filter((fingerprint) => !fetchReport[fingerprint].ok)
            .reduce((acc, fingerprint) => {
              acc[fingerprint] = Object.keys(fetchReport[fingerprint])
                .filter((key) => !/ok/.test(key))
                .map((id) => allChainsById?.[id]?.displayName || id);
              return acc;
            }, {})
        }
      : {})
  };

  return cleanUp(report);
};
