import React from "react";
import PropTypes from "prop-types";

import { VerticalVideoCarousel } from "@washingtonpost/media-components/core/video/vertical-carousel";

const VideoCarousel = ({ items = [] }) => {
  if (!items.length) return null;

  return (
    <VerticalVideoCarousel
      videos={items}
      config={{ carouselItemHeight: 500 }}
    />
  );
};

VideoCarousel.propTypes = {
  items: PropTypes.array
};

export default VideoCarousel;
