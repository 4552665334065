import get from "lodash.get";
import transform from "lodash.transform";
import isObject from "lodash.isobject";
import snakecase from "lodash.snakecase";

export const isGridItem = (itemType) => /^grid(\/.+)?$/.test(itemType);

export const isSeparatorItem = (itemType) =>
  /^separator(\/.+)?$/.test(itemType);

export const getSeparators = (child, regions) => {
  const separators = [];
  child.forEach((c, i) => {
    if (isGridItem(c.itemType)) {
      if (c.separators) {
        if (c.separators.top) {
          c.separators.top.regionIndex = regions.length;
          c.separators.top.itemIndex = i;
          separators.push(c.separators.top);
        }
        if (c.separators.bottom) {
          c.separators.bottom.regionIndex = regions.length;
          c.separators.bottom.itemIndex = i + 1;
          separators.push(c.separators.bottom);
        }
        delete c.separators;
      }
    }
  });
  return separators;
};

export const injectSeparators = (separators, regions) => {
  if (separators && separators.length) {
    let separatorsAdded = 0;
    separators.forEach((separator) => {
      const regionIndex = separator.regionIndex;
      delete separator.regionIndex;
      const itemIndex = separator.itemIndex;
      delete separator.itemIndex;
      regions[regionIndex].items.splice(
        itemIndex + separatorsAdded,
        0,
        separator
      );
      separatorsAdded += 1;
    });
  }
  return regions;
};

const isOkToRemove = (obj, options) => {
  const { bps, bpExceptions } = options;
  const objBps = Object.keys(get(obj, "layout_attributes") || {});
  const bpsOk = !bps || bps.some((bp) => objBps.includes(bp));
  const bpExceptionsOk =
    !bpExceptions || !bpExceptions.some((bp) => objBps.includes(bp));
  return bpsOk && bpExceptionsOk;
};

/*
 * @param {obj} json -- the json app output
 * @param {array} keys -- the list of keys to remove
 *
 * @return {obj} -- the modified json app output.
 *
 * The code goes through all region items (e.g. chains), tables and features. It
 * removes each of the specified keys from the (top level object only) it finds.
 * Used to remve `card_info` which was added to facilitate the cardify method.
 */
export const removeKeys = (json, keys = [], options = {}) => {
  (json.regions || []).forEach((region) => {
    (region.items || []).forEach((regionItem) => {
      const isOkToRemoveFromRegion = isOkToRemove(regionItem, options);
      keys.forEach((key) => {
        if (isOkToRemoveFromRegion) delete regionItem[key];
      });
      (regionItem.items || []).forEach((tableItem) => {
        const isOkToRemoveFromTable = isOkToRemove(tableItem, options);
        keys.forEach((key) => {
          if (isOkToRemoveFromTable) delete tableItem[key];
        });
        (tableItem.items || []).forEach((item) => {
          const isOkToRemoveFromItem = isOkToRemove(item, options);
          keys.forEach((key) => {
            if (isOkToRemoveFromItem) delete item[key];
          });
          (item.items || []).forEach((subitem) => {
            keys.forEach((key) => {
              if (isOkToRemoveFromItem) delete subitem[key];
            });
          });
        });
      });
    });
  });
  return json;
};

/*
 * @param {array} items -- list of items
 * @param {keys} keys -- list of keys to look for in each item
 *
 * @return {bool} -- whether any of the keys are present in any of the items
 */
const checkIfKey = (items, keys) =>
  items.some((item) => keys.some((key) => !!get(item, key)));

/*
 * @param {array} regions -- the regions part of the json
 * @param {array} keys -- the keys to look for on items
 * @param {obj} enhancment -- the ehancment to spread into matchng chains
 *
 * @return {array} -- the modified regions
 *
 * The code iterates through items in regions (e.g. chains), tables, features and subitems.
 * If it finds that any of the keys exists on any of these things, it inects enhancment into the
 * region item (e.g. chain)
 *
 * Used by injectDisplayContext (see below)
 */
const injectIntoChainIfKey = (regions, keys, enhancement) => {
  (regions || []).forEach((region) => {
    (region.items || []).forEach((regionItem, i) => {
      let matches = false;
      if (isGridItem(regionItem.itemType)) {
        const matchesRegion = checkIfKey([regionItem], keys);
        if (matchesRegion) matches = true;
        else {
          const tableItems = regionItem.items || [];
          const matchesTable = checkIfKey(tableItems, keys);
          if (matchesTable) matches = true;
          else {
            tableItems.forEach((tableItem) => {
              const items = tableItem.items || [];
              const matchesItem = checkIfKey(items, keys);
              if (matchesItem) matches = true;
              else {
                items.forEach((item) => {
                  const subItems = item.items || [];
                  const matchesSubItem = checkIfKey(subItems, keys);
                  if (matchesSubItem) matches = true;
                });
              }
            });
          }
        }
      }
      if (matches) {
        region.items[i] = { ...regionItem, ...enhancement };
        const nextRegionItem = region.items?.[i + 1];
        if (isSeparatorItem(nextRegionItem.itemType)) {
          region.items[i + 1] = { ...nextRegionItem, ...enhancement };
        }
      }
    });
  });
  return regions;
};

/*
 * @param {array} regions -- the regions part of the json
 *
 * @return {array} -- the regions with displayContent injected as appropriate
 *
 * Uses injectIntoChainIfKey (see above) to inject the displayContext if a chain or any
 * of its children tables, features, or subitems has a label.form
 */
export const injectDisplayContext = (regions) =>
  injectIntoChainIfKey(
    regions,
    ["label.form", "cta.form", "topper_label.form"],
    { displayContext: ["front"] }
  );

// TODO: Better toSnakeCase?
export const toSnakeCase = (obj) =>
  transform(obj, (acc, value, key, target) => {
    const snakeKey = Array.isArray(target) ? key : snakecase(key);
    acc[snakeKey] = isObject(value) ? toSnakeCase(value) : value;
  });
