const normalizeConfig = (config) => {
  const { source, contentService, contentConfigValues, query, filter } = config;
  return {
    source: source || contentService,
    query: query || contentConfigValues,
    filter
  };
};

export default function Consumer(ComponentToBeWrapped) {
  class WithFetchMixin extends ComponentToBeWrapped {
    constructor(props) {
      super(props);
      this.state = this.state || {};
    }

    getContent(config) {
      Object.keys(config).forEach((k) => {
        const contentConfig = normalizeConfig(config[k]);
        if (k === "noConfig" || contentConfig.source === "empty") {
          this.state[k] = {};
          return;
        }
        const content = this.props.cache.getContent(contentConfig);
        if (content) {
          this.state[k] = content;
        }
      });
    }

    // eslint-disable-next-line class-methods-use-this
    fetchContent(config) {
      if (!this.props.cache) {
        throw new Error("JSON app not properly seeded with cache");
      }
      this.getContent(config);
      const fetchesNeeded = Object.keys(config).filter(
        (key) => !this.state[key] && this.state[key] !== null
      );
      fetchesNeeded.forEach((k) => {
        const contentConfig = normalizeConfig(config[k]);
        if (Object.keys(contentConfig).length === 0) {
          return Promise.resolve(null);
        }
        return this.props.cache
          .fetchContent(contentConfig)
          .then((res) => {
            return {
              k,
              res
            };
          })
          .catch(() => {
            return { k, res: null };
          });
      });
    }
  }
  return WithFetchMixin;
}
